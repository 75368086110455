import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'

//中间菜单隐藏显示
var mouseover_tid = [];
var mouseout_tid = [];
$(document).ready(function () {
  $(".header .center-menu .menu-item").each(function (index) {
    if(index == 0 || index == 2){
      $(this).hover(
        // 鼠标进入
        function () {
          var _self = this;
          // 停止卷起事件
          clearTimeout(mouseout_tid[ index ]);
          // 当鼠标进入超过 0.2 秒, 展开菜单, 并记录到线程 ID 中
          mouseover_tid[ index ] = setTimeout(function () {
            $(_self).addClass('show');
            $('.header .center-menu .menu-show-bg').fadeIn(300)
          }, 101);
        },
        // 鼠标离开
        function () {
          var _self = this;
          // 停止展开事件
          clearTimeout(mouseover_tid[ index ]);
          // 当鼠标离开超过 0.2 秒, 卷起菜单, 并记录到线程 ID 中
          mouseout_tid[ index ] = setTimeout(function () {
            $(_self).removeClass('show');
            $('.header .center-menu .menu-show-bg').fadeOut(300)
          }, 101);
        });
    }
    });
});

//中间菜单法律观察swiper
var centerNavFLGCswiper = new Swiper(".header .flgc .mySwiper", {
  slidesPerView: 1,
  slidesPerGroupSkip: 1,
  spaceBetween: 30,
  breakpoints: {
    769: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 30,
    },
  },
  pagination: {
    el: ".header .flgc .swiper-pagination",
    clickable: true,
  },
});

//主菜单法律观察swiper
var MenuNavFLGCswiper = new Swiper(".menu .flgc .mySwiper", {
  slidesPerView: 1,
  slidesPerGroupSkip: 1,
  spaceBetween: 30,
  breakpoints: {
    768: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 30,
    },
  },
  pagination: {
    el: ".menu .flgc .swiper-pagination",
    clickable: true,
  },
});

//主菜单专业领域swiper
var zylyswiper = new Swiper(".menu .zyly-sub-nav .mySwiper", {
  effect : 'fade',
  fadeEffect: {
    crossFade: true,
  },
  allowTouchMove: false,
});
!(function(){
  $('.menu .zyly .zyly-tab div').eq(0).addClass('on')
})()
$('.menu .zyly .zyly-tab div').hover(function(){
  let index = $(this).index()
  console.log(index)
  $(this).addClass('on').siblings().removeClass('on')
  zylyswiper.slideTo(index)
})

//页面滚动监听
$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度

  if (scroH > 100) {
    $('header .right-nav-icon').addClass('scroll')
  }else{
    $('header .right-nav-icon').removeClass('scroll')
  }

  $('.common-search-hint').removeClass('show')
  //滚动页面收起搜索框
  setTimeout(function(){
    $('header .right-nav-icon').removeClass('scroll-open-search')
  },500)

  //内页banner位移效果
  let mask_opacity = ( scroH / 1000 ) > 0.5 ? 0.5 : scroH / 1000
  if($('.law-observe-banner').length > 0){
    $('.law-observe-banner .bg-img').css('transform','translateY('+ scroH / 5 +'px)')
    $('.law-observe-banner .banner-mask').css('opacity',mask_opacity)
  }
  if($('.field-banner').length > 0){
    $('.field-banner .bg-img').css('transform','translateY('+ scroH / 5 +'px)')
  }
  if($('.about-know-banner').length > 0){
    $('.about-know-banner .bg-img').css('transform','translateY('+ scroH / 5 +'px)')
    $('.about-know-banner .banner-mask').css('opacity',mask_opacity)
  }
  if($('.about-banner').length > 0){
    $('.about-banner .bg-img').css('transform','translateY('+ scroH / 5 +'px)')
    $('.about-banner .banner-mask').css('opacity',mask_opacity)
  }
  if($('.news-banner').length > 0){
    $('.news-banner .bg-img').css('transform','translateY('+ scroH / 5 +'px)')
    $('.news-banner .banner-mask').css('opacity',mask_opacity)
  }

});

//内页banner文字效果
$(document).ready(function(){
  if($('.law-observe-banner').length > 0){
    $('.law-observe-banner').addClass('active')
  }
  if($('.field-banner').length > 0){
    $('.field-banner').addClass('active')
  }
  if($('.about-banner').length > 0){
    $('.about-banner').addClass('active')
  }
  if($('.news-banner').length > 0){
    $('.news-banner').addClass('active')
  }
})


//点击搜索icon，显示搜索框
$('header .right-nav-icon .open-search').on('click',function(){
  $('header .right-nav-icon').addClass('scroll-open-search')
})
//点击搜索外的元素收起搜索框和搜索提示框
$(document).on('click',function(e){
  if($(e.target).parents('.menu-icon-cont').length < 1){
    $('header .right-nav-icon').removeClass('scroll-open-search')
  }
  if($(e.target).parents('.common-search-container').length < 1){
    $('.common-search-hint').removeClass('show')
    let inputEl = $('.common-search-container').find('input.search-input')
    for (let i=0;i<inputEl.length;i++){
      if($(inputEl[i]).attr('id') != 'search-q'){
        $(inputEl[i]).val('')
      }
    }
  }
})

// 点击menu图标
$('[data-open="cmenu"]').on('click', function () {
  let winW = $(window).width()
  if ($('header .right-nav-icon .menu-icon').hasClass('active')) {
    // 如果菜单已显示
    $('.common-search-hint').removeClass('show')
    $('.common-search-container').find('input.search-input').val('')
    if(winW >= 992 || !$('.menu .sub-menu').hasClass('mob-show')){
      // 大屏操作或者移动端二级菜单未显示时的操作，收起一级菜单
      $('header .right-nav-icon .menu-icon').removeClass('active')
      $('header .right-nav-icon .hamburger').removeClass('active')
      $('header .right-nav-icon').removeClass('menu-open')
      $('.menu .main-menu li').removeClass('on')
      $('header .menu .sub-menu>div').removeClass('subnav-show')
      $('header .menu').removeClass('show')
    }else{
      // 移动端且二级菜单已显示时的操作，先收起二级菜单，0.4秒后收起一级菜单
      $('.menu .sub-menu').removeClass('mob-show')
      setTimeout(function(){
        $('header .right-nav-icon .menu-icon').removeClass('active')
        $('header .right-nav-icon .hamburger').removeClass('active')
        $('header .right-nav-icon').removeClass('menu-open')
        $('.menu .main-menu li').removeClass('on')
        $('header .menu .sub-menu>div').removeClass('subnav-show')
        $('header .menu').removeClass('show')
      },400)
    }
  } else {
    // 如果菜单未显示，显示主菜单
    $('header .right-nav-icon .menu-icon').addClass('active')
    $('header .right-nav-icon .hamburger').addClass('active')
    $('header .right-nav-icon').removeClass('scroll-open-search').addClass('menu-open')
    $('header .menu').addClass('show')
    if(winW >= 992){
      $('.menu .main-menu .zyly-btn').addClass('on')
      $('header .menu .sub-menu .zyly').addClass('subnav-show')
    }
  }
})

// 二级菜单切换
$('.menu .main-menu .zyly-btn').on('click',function(){
  let winW = $(window).width()
  $(this).addClass('on').siblings().removeClass('on')
  $('.menu .sub-menu .zyly').addClass('subnav-show')
  $('.menu .sub-menu .flgc').removeClass('subnav-show')
  if(winW < 992){
    $('.menu .sub-menu').addClass('mob-show')
  }
})
$('.menu .main-menu .flgc-btn').on('click',function(){
  let winW = $(window).width()
  $(this).addClass('on').siblings().removeClass('on')
  $('.menu .sub-menu .flgc').addClass('subnav-show')
  $('.menu .sub-menu .zyly').removeClass('subnav-show')
  if(winW < 992){
    $('.menu .sub-menu').addClass('mob-show')
  }
})
//移动端关闭二级菜单
$('.menu .sub-menu .mob-back').on('click',function(){
  $('.menu .sub-menu').removeClass('mob-show')
})


//添加
!(function(){
  let $moreThemeArr = $('.center-menu .more-theme a')
  $.each($moreThemeArr,function(index,value){
    $(value).find('.ani-y').css('transition-delay', index*0.01+'s')
  })
})()
